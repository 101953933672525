import { Badge, HStack, Stack, useDisclosure } from "@chakra-ui/react";
import { LayoutDefault } from "App/layout/LayoutDefault";
import Card from "components/card/Card";
import React from "react";
import DataTable from "react-data-table-component";
import LoadingTable from "components/loading/LoadingTable";
import { useHistory, useLocation } from "react-router-dom";
import { useAuthentificationContext } from "context/auth-context";
import { PageHeader } from "App/layout/PageHeader";
import FilterTextButton from "components/filter/filter-text/FilterTextButton";
import { useFindInscriptionByFilters } from "./services/useFindInscriptionByFilters";
import dayjs from "dayjs";
import FilterCheckboxButton from "components/filter/filter-checkbox/FilterCheckboxButton";
import { FaPlus } from "react-icons/fa";
import ModalCreateInscription from "./_partials/ModalCreateInscription";
import {
Button
} from "components/ui/button";
import { PlusIcon } from "@radix-ui/react-icons";

const PageListeInscriptions = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const {
    onOpen: onOpenModalCreateInscription,
    isOpen: isOpenModalCreateInscription,
    onClose: onCloseModalCreateInscription,
  } = useDisclosure();

  const { inscription, isLoading } = useFindInscriptionByFilters(params);

  const { isAdmin, isEnseignement } = useAuthentificationContext();

  const columns = [
    {
      name: "Nom",
      selector: (row) => row?.eleve?.nom,
      sortable: true,
    },
    {
      name: "Prénom",
      selector: (row) => row?.eleve?.prenom,
      sortable: true,
    },
    {
      name: "Date Inscription",
      selector: (row) => dayjs(row?.dtInscription).format("DD/MM/YYYY HH:mm"),
      sortable: true,
    },
    {
      name: "Statut",
      selector: (row) => {
        if (row?.dtInscription && !row?.dtValidation) {
          return <Badge colorScheme="blue">En attente</Badge>;
        }
        if (row?.dtValidation) {
          return <Badge colorScheme="green">Validé</Badge>;
        }
      },
      sortable: true,
    },
    {
      name: "Classe 2022/2023",
      selector: (row) => (
        <HStack>
          <Badge
            colorScheme={
              row?.etablissementAnterieur === "CIH" ? "orange" : "yellow"
            }
          >
            {row?.etablissementAnterieur}
          </Badge>
          <Badge>{row?.classeAnterieure}</Badge>
        </HStack>
      ),
      sortable: true,
    },
  ];

  return (
    <LayoutDefault>
      <Stack>
        <PageHeader
          title="Inscriptions"
          filters={
            <>
              <FilterTextButton id="filter[eleve][nom]" label="Nom" />
              <FilterTextButton id="filter[eleve][prenom]" label="Prénom" />
              <FilterCheckboxButton
                id="filter[statut][]"
                label="Statut"
                options={["En attente", "Validé"]}
              />
              <FilterCheckboxButton
                id="filter[etablissementAnterieur][]"
                label="Établissement 2022/2023"
                options={["CE", "CIH"]}
              />
              <FilterCheckboxButton
                id="filter[classeAnterieure][]"
                label="Classe 2022/2023"
                options={[
                  "Mini Club 1",
                  "Mini Club 2",
                  "CP A",
                  "CP B",
                  "CP C",
                  "N Préparatoire",
                  "N1 A",
                  "N1 B",
                  "N1 C",
                  "N1 D",
                  "N2 A",
                  "N2 B",
                  "N2 C",
                  "N3",
                  "N4",
                  "N5",
                  "N6",
                  "N Jeune Débutant",
                  "N Jeune 1",
                  "N Jeune 2",
                ]}
              />
            </>
          }
          buttons={
            <HStack>
              <Button
                onClick={onOpenModalCreateInscription}
              >
                 <PlusIcon className="mr-2 h-4 w-4" /> Nouvelle inscription
              </Button>
            </HStack>
          }
        />
        {/*   <HStack flex={{ base: 1, xl: 0 }} spacing={1} justify="flex-end">
            {(isAdmin || isEnseignement) && (
              <>
               <Tooltip label="Exporter">
                  <IconButton
                    onClick={() => handleExtract()}
                    aria-label="Extract"
                    icon={<AiOutlineCloudDownload />}
                    isLoading={isLoadingExtract}
                  />
                </Tooltip> 
              </>
            )}
          </HStack> 
        </PageHeader>*/}
        <Card classCard="max-w-full" classContent="p-0" >
          <DataTable
            columns={columns}
            data={inscription}
            progressPending={isLoading}
            progressComponent={<LoadingTable />}
            highlightOnHover
            striped
            pagination
            pointerOnHover
            // paginationResetDefaultPage={resetPaginationToggle}
            paginationComponentOptions={{
              rangeSeparatorText: "sur",
              rowsPerPageText: "Lignes par page",
            }}
            onRowClicked={(item) => push(`/eleve/${item?.eleve?.id}`)}
          />
        </Card>
      </Stack>
      <ModalCreateInscription
        isOpen={isOpenModalCreateInscription}
        onClose={onCloseModalCreateInscription}
      />
    </LayoutDefault>
  );
};

export default PageListeInscriptions;
