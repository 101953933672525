import {
  Stack,
  HStack,
  Alert,
  AlertIcon,
  IconButton,
  MenuItem,
  useDisclosure,
} from "@chakra-ui/react";
import LabelValeur from "components/LabelValeur";
import Card from "components/card/Card";
import React from "react";
import { BsFillGearFill } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import ModalCreatePaiement from "./_partials/ModalCreatePaiement";
import dayjs from "dayjs";

const CardPaiements = ({ paiement }) => {
  const canEdit = true;

  const {
    onOpen: onOpenModalCreatePaiement,
    isOpen: isOpenModalCreatePaiement,
    onClose: onCloseModalCreatePaiement,
  } = useDisclosure();

  const paiementRegroupes = Object.entries(
    paiement?.reduce((acc, obj) => {
      const label = obj.inscription.anneeScolaire.label;

      if (!acc[label]) {
        acc[label] = [];
      }

      acc[label].push(obj);

      return acc;
    }, {})
  );

  return (
    <Card
      colorTitle="green.500"
      title="Paiements"
      maxWidth="80vw"
      overflow="auto"
      menuItems={
        canEdit && (
          <MenuItem icon={<FaPlus />} onClick={onOpenModalCreatePaiement}>
            Ajouter
          </MenuItem>
        )
      }
    >
      <Stack flex={1}>
        {paiement ? (
          paiementRegroupes?.map(([k, annee]) => (
            <HStack key={k}>
              <LabelValeur label="Année Scolaire" flex={1}>
                {k}
              </LabelValeur>
              {annee?.map((item) => (
                <LabelValeur
                  key={item?.date}
                  label={dayjs(item?.date)?.format("DD/MM/YYYY")}
                  flex={1}
                >
                  {item?.montant}€
                </LabelValeur>
              ))}
              <IconButton aria-label="Gérer" icon={<BsFillGearFill />} />
            </HStack>
          ))
        ) : (
          <Alert status="info">
            <AlertIcon />
            Aucun paiement
          </Alert>
        )}
      </Stack>
      <ModalCreatePaiement
        isOpen={isOpenModalCreatePaiement}
        onClose={onCloseModalCreatePaiement}
      />
    </Card>
  );
};

export default CardPaiements;
