import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Divider,
  HStack,
  MenuItem,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Formiz, useForm } from "@formiz/core";
import ButtonDelete from "components/button/ButtonDelete";
import Card from "components/card/Card";
import React from "react";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useDeleteEleveClasse } from "../services/useDeleteEleveClasse";
import ModalCreateClasseEleveClasse from "./ModalCreateClasseEleveClasse";
import { FaPlus } from "react-icons/fa";
import { Button } from "components/ui/button";

const CardClasses = (props) => {
  const {
    eleve,
    classes,
    // canEdit
  } = props;
  const { eleveId } = useParams();
  const formClasses = useForm({ subscribe: "form" });
  const queryClient = useQueryClient();

  const [isEditing, setIsEditing] = useState(false);
  const {
    onOpen: onOpenAddClasse,
    isOpen: isOpenAddClasse,
    onClose: onCloseAddClasse,
  } = useDisclosure();

  const canEdit = true;

  const handleCallbackSuccess = async () => {
    await queryClient.invalidateQueries(["elevesClasse-eleve", eleveId]);
    // onClose();
  };

  const { mutate: mutationDelete } = useDeleteEleveClasse({
    handleCallbackSuccess,
  });

  const handleDelete = (id) => {
    mutationDelete(id);
  };

  const checkConflicts = () => {};

  return (
    <>
      <Card
        classCard="relative h-full bg-primary"
        classContent={`px-2 pt-8 h-full flex flex-col items-center ${
          classes?.length > 1 ? "pb-0" : "pb-3"
        }`}
      >
        <p className="absolute top-1 left-3 font-bold text-sm text-secondary">
          {classes[0]?.classe?.etablissement?.nom}
        </p>
        <p className="absolute top-2 right-3 font-medium text-xs text-secondary">
          {`${classes?.length} attribution${classes?.length > 1 ? "s" : ""}`}
        </p>
        <div className="w-full h-full bg-gradient-to-r from-green-400 to-white rounded-lg flex justify-center items-center">
          <p className="text-3xl font-bold text-slate-600">
            {classes[0]?.classe?.nom}
          </p>
        </div>
        {classes?.length > 1 ? (
          <Button
            variant="ghost"
            className="h-fit px-1 py-0.5 text-xs my-1 text-secondary w-fit"
          >
            Voir +
          </Button>
        ) : null}

        {/* <Formiz autoform connect={formClasses}> */}
        {/* {canEdit && (
            <EditingControlsCard
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            >
              <Button
                onClick={onOpenAddClasse}
                size="sm"
                colorScheme="green"
                borderTopLeftRadius={0}
              >
                Ajouter
              </Button>
            </EditingControlsCard>
          )} */}
        {/* <Stack px={canEdit && 5}>
            {classes?.map((cl) => (
              <React.Fragment key={cl.classe?.id}>
                <HStack>
                  <Box w="100%">
                    <Badge
                      key={cl?.classe?.id}
                      colorScheme={
                        cl?.classe?.etablissement?.nom === "CE"
                          ? "blue"
                          : "green"
                      }
                    >
                      {cl?.classe?.nom}
                    </Badge>
                  </Box>
                  <Box w="50%">
                    <Text fontWeight="bold">
                      {cl?.classe?.etablissement?.nom}
                    </Text>
                  </Box>
                  <Box w="100%">
                    <Text fontWeight="bold">{cl?.anneeScolaire?.label}</Text>
                  </Box>
                  <Box w="30%" display="flex" justifyContent="flex-end">
                    {canEdit && isEditing && (
                      <ButtonDelete
                        size="sm"
                        colorScheme="red"
                        onConfirmDelete={() => handleDelete(cl?.id)}
                      />
                    )}
                  </Box>
                </HStack>
                <Divider />
              </React.Fragment>
            ))}

            {classes?.length < 1 && (
              <Alert status="info">
                <AlertIcon />
                Aucun résultat.
              </Alert>
            )}
          </Stack>
        </Formiz> */}
      </Card>
      <ModalCreateClasseEleveClasse
        eleve={eleve}
        isOpen={isOpenAddClasse}
        onClose={onCloseAddClasse}
      />
    </>
  );
};

export default CardClasses;
