// import { useForm } from "@formiz/core";
import React from "react";
import { Button } from "components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { Separator } from "components/ui/separator";
import { useUpdateResponsableLegal } from "../services/useUpdateResponsableLegal";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const formSchema = z.object({
  nom: z.string().min(2, {
    message: "Le nom doit comporter min 2 caractères.",
  }),
  prenom: z.string().min(2, {
    message: "Le prénom doit comporter min 2 caractères.",
  }),
  profession: z.string().min(2, {
    message: "La profession doit comporter min 2 caractères.",
  }),
  adresse: z.string().min(2, {
    message: "L'adresse doit comporter min 2 caractères.",
  }),
  ville: z.string().min(2, {
    message: "La ville doit comporter min 2 caractères.",
  }),
  email: z.string().min(2, {
    message: "L'email doit comporter min 2 caractères.",
  }),
  telephone: z.string(),
  codePostal: z.number(),
});

const DialogResponsableLegal = (props) => {
  const { responsable, open, setOpen } = props;
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      nom: responsable?.nom,
      prenom: responsable?.prenom,
      profession: responsable?.profession,
      adresse: responsable?.adresse,
      codePostal: responsable?.codePostal,
      ville: responsable?.ville,
      email: responsable?.email,
      telephone: responsable?.telephone,
    },
  });

  const handleCallbackSuccess = async ({ inscription }) => {
    await queryClient.invalidateQueries(
      "eleveResponsableLegaux",
      params?.toString()
    );
    setOpen(false);
  };

  const {
    mutate: updateResponsableLegal,
    isLoading: isLoadingUpdateResponsableLegal,
  } = useUpdateResponsableLegal({
    responsableLegauxId: responsable?.id,
    handleCallbackSuccess,
  });

  function onSubmit(values) {
    const data = {
      ...values,
    };

    updateResponsableLegal(data);

    return null;
  }

  return (
    <Dialog
      open={open}
      onOpenChange={(isOpen) => {
        if (isOpen === true) return;
        setOpen(false);
      }}
    >
      <DialogContent className="sm:max-w-[425px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <DialogHeader>
              <DialogTitle>Modifier un responsable légal</DialogTitle>
              <DialogDescription>
                Apportez des modifications au responsable légal ici. Cliquez sur
                Enregistrer lorsque vous avez terminé.
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-2">
              <FormField
                control={form.control}
                name="nom"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-4 items-center gap-x-4">
                    <FormLabel className="text-right">Nom</FormLabel>
                    <FormControl>
                      <Input
                        className="col-span-3"
                        placeholder="shadcn"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="col-span-3 col-start-2" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="prenom"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-4 items-center gap-x-4">
                    <FormLabel className="text-right">Prénom</FormLabel>
                    <FormControl>
                      <Input
                        className="col-span-3"
                        placeholder="shadcn"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="col-span-3 col-start-2" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="profession"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-4 items-center gap-x-4">
                    <FormLabel className="text-right">Profession</FormLabel>
                    <FormControl>
                      <Input
                        className="col-span-3"
                        placeholder="shadcn"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="col-span-3 col-start-2" />
                  </FormItem>
                )}
              />
              <Separator />
              <FormField
                control={form.control}
                name="adresse"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-4 items-center gap-x-4">
                    <FormLabel className="text-right">Adresse</FormLabel>
                    <FormControl>
                      <Input
                        className="col-span-3"
                        placeholder="shadcn"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="col-span-3 col-start-2" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="codePostal"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-4 items-center gap-x-4">
                    <FormLabel className="text-right">Code postal</FormLabel>
                    <FormControl>
                      <Input
                        className="col-span-3"
                        placeholder="shadcn"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="col-span-3 col-start-2" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="ville"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-4 items-center gap-x-4">
                    <FormLabel className="text-right">Ville</FormLabel>
                    <FormControl>
                      <Input
                        className="col-span-3"
                        placeholder="shadcn"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="col-span-3 col-start-2" />
                  </FormItem>
                )}
              />
              <Separator />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-4 items-center gap-x-4">
                    <FormLabel className="text-right">Email</FormLabel>
                    <FormControl>
                      <Input
                        className="col-span-3"
                        placeholder="shadcn"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="col-span-3 col-start-2" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="telephone"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-4 items-center gap-x-4">
                    <FormLabel className="text-right">Téléphone</FormLabel>
                    <FormControl>
                      <Input
                        className="col-span-3"
                        placeholder="shadcn"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="col-span-3 col-start-2" />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter>
              <Button type="submit">Enregistrer</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default DialogResponsableLegal;
