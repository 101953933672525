import {
  Grid,
  GridItem,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useFindDashboardAccueil } from "App/dashboard/services/useFindDashboardAccueil";
import { LayoutDefault } from "App/layout/LayoutDefault";
import Card from "components/card/Card";
import { useAuthentificationContext } from "context/auth-context";
import React from "react";
import { useHistory } from "react-router-dom";
import CardClassesDashboard from "../components/CardClassesDashboard";
import CardEleveDashboard from "../components/CardEleveDashboard";
import dayjs from "dayjs";
import { FcDeleteDatabase } from "react-icons/fc";

const PageAccueil = () => {
  const { push } = useHistory();
  const {
    canAccessCoursAppel,
    canAccessAbsences,
    canAccessClasses,
    canAccessEleves,
  } = useAuthentificationContext();

  const {
    countEleves,
    countClasses,
    isLoading: isLoadingDashboardAccueil,
  } = useFindDashboardAccueil();

  return (
    <LayoutDefault>
      {isLoadingDashboardAccueil && <Skeleton height="100%" />}
      <Card
        height="100%"
        cursor="pointer"
        onClick={() => push("/inscriptions")}
      >
        <Heading size="md" textTransform="uppercase">
          Inscriptions
        </Heading>
      </Card>
      {/* <Grid
        templateAreas={{
          base: `
          "coursJour classes"
          "absences eleves"`,
          lg: `
                  "coursJour classes absences eleves ."
                  ". . . . ."`,
        }}
        gridTemplateRows={{ base: "repeat(2, 1fr)", lg: "repeat(2, 1fr)" }}
        gridTemplateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(5, 1fr)" }}
        gap="2"
      >
        {canAccessCoursAppel && (
          <GridItem gridArea="coursJour">
            <Card height="100%" cursor="pointer" onClick={() => push("/cours")}>
              <Heading size="md" textTransform="uppercase">
                Cours du jour
              </Heading>
            </Card>
          </GridItem>
        )}
        {canAccessAbsences && (
          <GridItem gridArea="absences">
            <Card
              height="100%"
              cursor="pointer"
              onClick={() =>
                push(
                  `/absences?filter[date][du]=${dayjs().format(
                    "YYYY-MM-01"
                  )}&filter[date][au]=${dayjs().format(
                    `YYYY-MM-${dayjs().daysInMonth()}`
                  )}`
                )
              }
              justify="center"
              align="center"
            >
              <Icon
                as={FcDeleteDatabase}
                w="4rem"
                h="4rem"
                color="red.500"
                bgColor="gray.100"
                p={2}
                borderRadius="full"
              />
              <Stack spacing={-1} justify="center" align="center">
                <Text fontSize={12}>Liste</Text>
                <Heading size="md" textTransform="uppercase">
                  Absences
                </Heading>
              </Stack>
            </Card>
          </GridItem>
        )}
        {canAccessClasses && (
          <GridItem gridArea="classes">
            <CardClassesDashboard countClasses={countClasses} />
          </GridItem>
        )}
        {canAccessEleves && (
          <GridItem gridArea="eleves">
            <CardEleveDashboard countEleves={countEleves} />
          </GridItem>
        )}
      </Grid> */}
    </LayoutDefault>
  );
};

export default PageAccueil;
