import { useQuery } from "react-query";
import Axios from "config/axios";

/**
 * Return professeur
 * @author <issadricipro@gmail.com>
 *
 */
export const useFindCours = () => {
  const { data, isLoading, isFetching } = useQuery(
    ["cours"],
    () => {
      return Axios.get(`/cours`);
    },
    { retry: false, refetchOnWindowFocus: true }
  );

  const cours = data?.cours;
  
  return {
    cours,
    isLoading,
    isFetching,
  };
};
