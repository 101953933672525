import { Badge, HStack, Stack, useDisclosure } from "@chakra-ui/react";
import { LayoutDefault } from "App/layout/LayoutDefault";
import React from "react";
import LoadingTable from "components/loading/LoadingTable";
import { useHistory, useLocation } from "react-router-dom";
import { useAuthentificationContext } from "context/auth-context";
import { PageHeader } from "App/layout/PageHeader";
import dayjs from "dayjs";
import { Button } from "components/ui/button";
import { PlusIcon } from "@radix-ui/react-icons";
import { useFindUtilisateurByFilters } from "./services/useFindUtilisateurByFilters";
import { DataTable } from "components/ui/data-table";
import { DataTableColumnHeader } from "components/ui/data-table-column-header";
import { DataTableRowActions } from "components/ui/data-table-row-actions";
import { DropdownMenuItem } from "components/ui/dropdown-menu";
import { MenubarShortcut } from "components/ui/menubar";
import Card from "components/card/Card";

const PageListeProfesseur = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const { utilisateur, isLoading } = useFindUtilisateurByFilters(params);

  const { isAdmin, isEnseignement } = useAuthentificationContext();

  const columns = [
    {
      accessorKey: "nom",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Nom" />
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "prenom",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Prénom" />
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "email",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Email" />
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "telephone",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Téléphone" />
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <div className="flex justify-end">
            <DataTableRowActions row={row}>
              {/* <DropdownMenuItem
                onClick={() => push(`/classe/${row?.original?.id}`)}
              >
                Voir{" "}
                <MenubarShortcut>
                  <EyeOpenIcon className="mr-2 h-4 w-4" />
                </MenubarShortcut>
              </DropdownMenuItem> */}
              {/* <DropdownMenuItem>
                Supprimer{" "}
                <MenubarShortcut>
                  <TrashIcon className="mr-2 h-4 w-4" />
                </MenubarShortcut>
              </DropdownMenuItem> */}
            </DataTableRowActions>
          </div>
        );
      },
    },
  ];

  return (
    <LayoutDefault isLoading={isLoading} dataAvailable={!!utilisateur}>
      <PageHeader
        title="Professeurs"
        buttons={
          <div>
            <Button
            // onClick={onOpenModalCreateInscription}
            >
              <PlusIcon className="mr-2 h-4 w-4" /> Nouveau professeur
            </Button>
          </div>
        }
      />

      <div className="flex-1">
        <Card classContent="p-4">
          {/* <Card classCard="relative h-full" classContent="pt-4"> */}
          <DataTable columns={columns} data={utilisateur} />
          {/* </Card> */}
        </Card>
      </div>
      {/* <ModalCreateInscription
        isOpen={isOpenModalCreateInscription}
        onClose={onCloseModalCreateInscription}
      /> */}
    </LayoutDefault>
  );
};

export default PageListeProfesseur;
