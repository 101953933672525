import {
  Alert,
  AlertIcon,
  Box,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import dayjs from "dayjs";
import { useFindCours } from "../services/useFindCours";
import { getWeekDays } from "functions/common-scope/Dates";
import { useHistory } from "react-router-dom";
import { LayoutDefault } from "App/layout/LayoutDefault";
import { PageHeader } from "App/layout/PageHeader";

const PageCours = () => {
  const { push } = useHistory();

  const {
    cours,
    isLoading: isLoadingCours,
    // isFetching: isFetchingCours,
  } = useFindCours();

  const dateNow = dayjs();

  const coursDuJour = cours?.filter((item) => {
    return item?.jour === getWeekDays()[dateNow.day()];
  });

  return (
    <LayoutDefault>
      <PageHeader title={`Cours du jour - ${dateNow?.format("DD/MM/YYYY")}`} />
      
      <Stack spacing={5}>
        {isLoadingCours &&
          [0, 1, 2, 3, 4].map((item) => <Skeleton key={item} height="30px" />)}

        {!isLoadingCours && (
          <Stack spacing={3}>
            {coursDuJour?.length < 1 && (
              <Alert status="info">
                <AlertIcon />
                Aucun résultat.
              </Alert>
            )}
            {coursDuJour?.map((item) => {
              return (
                <Box
                  key={item?.id}
                  paddingX={3}
                  paddingY={2}
                  borderRadius={7}
                  backgroundColor="#8bc34a"
                  shadow="md"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  onClick={() => push(`/cours/${item?.id}/appel`)}
                  cursor="pointer"
                >
                  <Stack flex={1} spacing={0}>
                    <Text
                      textTransform="uppercase"
                      fontWeight={700}
                      color="#FFF"
                    >
                      {item?.classe?.nom}
                    </Text>
                    <Text
                      textTransform="uppercase"
                      fontWeight={700}
                      fontSize={11}
                      color="#FFF"
                    >
                      {item?.classe?.etablissement?.nom === "CE"
                        ? "Centre Essalam"
                        : item?.classe?.etablissement?.nom}
                    </Text>
                  </Stack>
                  <Stack spacing={0} textAlign="end">
                    <Text
                      textTransform="uppercase"
                      fontWeight={700}
                      fontSize={11}
                      color="#FFF"
                    >
                      {item?.jour}
                    </Text>
                    <Text
                      textTransform="uppercase"
                      fontWeight={700}
                      fontSize={11}
                      color="#FFF"
                    >
                      {item?.heure}
                    </Text>
                  </Stack>
                </Box>
              );
            })}
          </Stack>
        )}
      </Stack>
    </LayoutDefault>
  );
};

export default PageCours;
