import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useAuthentificationContext } from "context/auth-context";
import { Image, Spinner, Stack } from "@chakra-ui/react";
import PropTypes from "prop-types";
import "./loader.css";

export const Authenticated = (props) => {
  const { authorisedUsers } = props;
  const { pathname } = useLocation();
  const { initializing, user } = useAuthentificationContext();
  const isInRoles = (roles) => {
    let isAuthorized = false;

    for (let index = 0; index < authorisedUsers.length; index++) {
      if (roles?.includes(authorisedUsers[index])) {
        isAuthorized = true;
      }
    }

    return isAuthorized;
  };

  if (initializing || (user !== null && user?.roles === undefined)) {
    return (
      <Stack
        flex={1}
        align="center"
        justify="center"
        w="100vw"
        h="100vh"
        bgColor="gray.100"
      >
        {/* <Image src="/logo.png" h={50} alt="logo" /> */}
        <div class="loader">
          <div class="pencil">
            <p>Loading...</p>
            <div class="top"></div>
          </div>
          <div class="stroke"></div>
        </div>
      </Stack>
    );
  }
  if (user === null) {
    return <Redirect to={{ pathname: "/login", state: { from: pathname } }} />;
  }

  if (
    user !== null &&
    !initializing &&
    !isInRoles(user?.roles) &&
    !authorisedUsers.includes("all")
  ) {
    return (
      <Redirect to={{ pathname: "/unauthorized", state: { from: pathname } }} />
    );
  }
  return (
    <>
      {user !== null &&
        (isInRoles(user?.roles) || authorisedUsers.includes("all")) && (
          <Route {...props} />
        )}
    </>
  );
};
Authenticated.propTypes = {
  authorisedUsers: PropTypes.instanceOf(Array).isRequired,
};
